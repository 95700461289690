import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import User from "./User";
import Account from "./Account";
import Alert from "./Alert";
import Stocks from "./Stocks";
import Subscriptions from "./Subscriptions";

const appReducer = combineReducers({
  theme: Theme,
  auth: Auth,
  user: User,
  account: Account,
  alert: Alert,
  stocks: Stocks,
  subscriptions: Subscriptions
});

const rootReducer = (state, action) => {
  if (action.type == "SIGNOUT_SUCCESS") {
    console.log("Cleaning up store")
    return appReducer(undefined, action)
  }

  if (action.type == "ACCOUNT_SWITCHED") {
    console.log("Cleaning up store")
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;
