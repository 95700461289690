import {
  ACCOUNT_RETRIEVED,
  ACCOUNT_SWITCHED,
  ACCOUNT_USERS_RETRIEVED,
  ACCOUNT_PERFORMANCE_MONITORS_RETRIEVED,
  ACCOUNT_UPTIME_MONITORS_RETRIEVED,
  ACCOUNT_ID,
  ACCOUNT_UPTIME_MONITOR_LATEST_CHECKS_RETRIEVED,
  ACCOUNT_PERFORMANCE_MONITOR_DOMAINS_RETRIEVED,
  ACCOUNT_UPTIME_MONITOR_CHECK_DETAILS_RETRIEVED,
  ACCOUNT_UPTIME_MONITOR_CHECK_LIST_RETRIEVED,
  ACCOUNT_BILLING_DETAILS_RETRIEVED,
  ACCOUNT_INTEGRATIONS_RETRIEVED
} from "../constants/Account";

const initState = {
  account: null,
  redirect: "",
  users: [],
  uptimeMonitors: null,
  performanceMonitors: null,
  browserMonitors: null,
  uptimeMonitorsDetails: {},
  uptimeMonitorsCheckList: {},
  uptimeMonitorsLatestChecks: {},
  performanceMonitorsData: {},
  performanceMonitorsSinglestats: {},
  performanceMonitorsDomains: {},
  usersToView: [],
  billingDetails: null,
  integrations: {}
};

const account = (state = initState, action) => {
  switch (action.type) {
    case ACCOUNT_SWITCHED:
      localStorage.setItem(ACCOUNT_ID, action.account.id);
      return initState;
    case ACCOUNT_RETRIEVED:
      localStorage.setItem(ACCOUNT_ID, action.account.id);
      return {
        ...state,
        account: action.account,
      };
    case ACCOUNT_INTEGRATIONS_RETRIEVED:
      return {
        ...state,
        integrations: action.integrations
      }
    case ACCOUNT_USERS_RETRIEVED:
      if (action.users != null) {
        let usersToView = [];

        usersToView.push({
          email: action.users["owner"]["email"],
          role: "owner",
          key: action.users["owner"]["id"],
          lastLogin: action.users["owner"]["last_login"],
          accountSettings: action.users["owner"]["account_settings"]
        });

        action.users.users.forEach((user) => {
          let role = "user";

          action.users.admins.forEach((ua) => {
            if (user.id === ua.id) {
              role = "admin";
            }
          });

          usersToView.push({
            email: user["email"],
            role: role,
            key: user["id"],
            lastLogin: user["last_login"],
            accountSettings: user["account_settings"]
          });
        });


        action.users.pending_invites.forEach((invite) => {
          usersToView.push({
            email: invite,
            role: "invited",
            key: invite,
            labels: []
          });
        });

        return {
          ...state,
          redirect: "",
          users: usersToView,
        };
      }
      break;
    case ACCOUNT_UPTIME_MONITORS_RETRIEVED:
      return {
        ...state,
        uptimeMonitors: action.uptimeMonitors,
      };
    case ACCOUNT_PERFORMANCE_MONITORS_RETRIEVED:
      // Convert location_config to easier to consume
      // locations list
      action.performanceMonitors.forEach((perfmon) => {
        let locations = []

        Object.keys(perfmon.location_config).forEach((region) => {
          let reg = perfmon.location_config[region]
          reg.forEach((loc) => {
            locations.push(`${region}-${loc}`)
          })
        })

        perfmon.locations = locations
      })

      return {
        ...state,
        performanceMonitors: action.performanceMonitors,
      };
    case ACCOUNT_UPTIME_MONITOR_LATEST_CHECKS_RETRIEVED:
      // state deep clone
      let lcData = JSON.parse(JSON.stringify(state.uptimeMonitorsLatestChecks));
      lcData[action.monitorUuid] = action.data;
      return {
        ...state,
        uptimeMonitorsLatestChecks: lcData,
      };
    case ACCOUNT_UPTIME_MONITOR_CHECK_DETAILS_RETRIEVED:
      let umCheckDetails = JSON.parse(
        JSON.stringify(state.uptimeMonitorsDetails)
      );
      if (umCheckDetails[action.monitorUuid] === undefined) {
        umCheckDetails[action.monitorUuid] = {};
      }
      umCheckDetails[action.monitorUuid][action.requestTimestamp] = action.data;
      return {
        ...state,
        uptimeMonitorsDetails: umCheckDetails,
      };
    case ACCOUNT_UPTIME_MONITOR_CHECK_LIST_RETRIEVED:
      let umCheckList = JSON.parse(
        JSON.stringify(state.uptimeMonitorsCheckList)
      );

      let perTimestampResults = {};

      if (umCheckList[action.monitorUuid] === undefined) {
        umCheckList[action.monitorUuid] = {};
      }

      let splitReqFilenames = (name) => {
        let split = name.split("_");
        let ts = split[2];
        let status = split[3].split(".")[0];
        return { ts: ts, status: status };
      };

      if (action.data.requests.length <= 0) {
        umCheckList[action.monitorUuid] = []
        return {
          ...state,
          uptimeMonitorsCheckList: umCheckList,
        };
      }

      if (action.requestTimestamp === 0) {
        umCheckList[action.monitorUuid]["newest"] = splitReqFilenames(
          action.data.requests[0]
        );
      }

      action.data.requests.forEach((el) => {
        let obj = splitReqFilenames(el);
        perTimestampResults[obj.ts] = obj.status;
      });

      umCheckList[action.monitorUuid][action.requestTimestamp] = perTimestampResults;

      return {
        ...state,
        uptimeMonitorsCheckList: umCheckList,
      };
    case ACCOUNT_PERFORMANCE_MONITOR_DOMAINS_RETRIEVED:
      // state deep clone
      let pmDomains = JSON.parse(
        JSON.stringify(state.performanceMonitorsDomains)
      );
      pmDomains[action.monitorUuid] = action.data;
      return {
        ...state,
        performanceMonitorsDomains: pmDomains,
      };
    case ACCOUNT_BILLING_DETAILS_RETRIEVED:
      return {
        ...state,
        billingDetails: action.data,
      };
    default:
      return state;
  }
};

export default account;
