let SLACK_SCOPES = "users:read.email,channels:join,mpim:read,channels:read,links:read,links:write,im:read,team:read,commands,im:write,channels:manage,groups:read,chat:write,reactions:write,workflow.steps:execute,users:read"

interface DtektConfig {
  NAME: string
  API_ENDPOINT_URL: string
  FRONTEND_URL: string
  GOOGLE_CLIENT_ID: string
  GOOGLE_REDIRECT_URI: string
  STRIPE_STARTER_PRICE_ID: string
  STRIPE_STARTER_PROD_ID: string
  STRIPE_BASIC_PRICE_ID: string
  STRIPE_BASIC_PROD_ID: string
  STRIPE_PREMIUM_PRICE_ID: string
  STRIPE_PREMIUM_PROD_ID: string
  UPTIME_MONITOR_ENABLE_TCP_CHECK: boolean
  APP_STAGING: boolean
  RUM_API_ADDRESS: string
  RUM_WS_API_ADDRESS: string
  TSDB_ADDRESS: string
  PAGERDUTY_REDIRECT_URI: string
  PAGERDUTY_APP_ID: string
  SLACK_INTEGRATION_INSTALLATION_URL: string
  FF_ACCOUNT_PREFILLING: boolean
}

const dev: DtektConfig = {
  NAME: "development",
  API_ENDPOINT_URL: "https://localhost:8443",
  FRONTEND_URL: "https://localhost:3000",
  GOOGLE_CLIENT_ID:
    "817043119548-kd4k7dnen4m0ngon1nsq5c1r3ecbe7an.apps.googleusercontent.com",
  GOOGLE_REDIRECT_URI:
    "https://localhost:3000/auth/login/google_oauth2/callback",
  STRIPE_STARTER_PRICE_ID: "price_1IF4xABWJus61oXex3Iez2OT",
  STRIPE_STARTER_PROD_ID: "prod_Ij7LqSX29Vqswd",
  STRIPE_BASIC_PRICE_ID: "price_1IF4zLBWJus61oXeOdQLuW4M",
  STRIPE_BASIC_PROD_ID: "prod_Iiqn4b6rKXj8FL",
  STRIPE_PREMIUM_PRICE_ID: "price_1IF50UBWJus61oXeueeoL6yr",
  STRIPE_PREMIUM_PROD_ID: "prod_Iivf2Il75snbTG",
  UPTIME_MONITOR_ENABLE_TCP_CHECK: true,
  RUM_API_ADDRESS: "https://rum.dtekt.io:8443",
  RUM_WS_API_ADDRESS: "wss://rum.dtekt.io:8443",
  // RUM_API_ADDRESS: "https://localhost:7001",
  // RUM_WS_API_ADDRESS: "wss://localhost:7001",
  TSDB_ADDRESS: "https://localhost:7002",
  APP_STAGING: true,
  PAGERDUTY_REDIRECT_URI: "https://localhost:3000/app/apps/integrations/pagerduty/complete",
  PAGERDUTY_APP_ID: "P5RQBZ9",
  SLACK_INTEGRATION_INSTALLATION_URL: `https://slack.com/oauth/v2/authorize?scope=${SLACK_SCOPES}&client_id=1316979747655.4131515818354&redirect_uri=https://localhost:3000/app/apps/integrations/slack/complete`,
  FF_ACCOUNT_PREFILLING: true
};

const prod: DtektConfig = {
  NAME: "production",
  API_ENDPOINT_URL: "https://api.myshares.ai",
  FRONTEND_URL: "https://api.myshares.ai",
  GOOGLE_CLIENT_ID: "817043119548-b51eujt0o04rpbljmnck89d0etd212mj.apps.googleusercontent.com",
  GOOGLE_REDIRECT_URI: "https://app.myshares.ai/auth/login/google_oauth2/callback",
  STRIPE_STARTER_PRICE_ID: "price_1IHUgtBWJus61oXecQK1vLgp",
  STRIPE_STARTER_PROD_ID: "prod_IjB6ZlbbnvAot9",
  STRIPE_BASIC_PRICE_ID: "price_1IHUeuBWJus61oXe0jEUxIdB",
  STRIPE_BASIC_PROD_ID: "prod_IjB5WXgLdiQ9Z2",
  STRIPE_PREMIUM_PRICE_ID: "price_1IHUhTBWJus61oXe0DzYFwZA",
  STRIPE_PREMIUM_PROD_ID: "prod_IjB5RsAR3NOHf6",
  UPTIME_MONITOR_ENABLE_TCP_CHECK: true,
  APP_STAGING: false,
  RUM_API_ADDRESS: "https://rum.dtekt.io:8443",
  RUM_WS_API_ADDRESS: "wss://rum.dtekt.io:8443",
  TSDB_ADDRESS: "https://tsdb.dtekt.io",
  PAGERDUTY_REDIRECT_URI: "https://api.myshares.ai/app/apps/integrations/pagerduty/complete",
  PAGERDUTY_APP_ID: "P5RQBZ9",
  SLACK_INTEGRATION_INSTALLATION_URL: `https://slack.com/oauth/v2/authorize?scope=${SLACK_SCOPES}&client_id=1316979747655.4131515818354&redirect_uri=https://api.myshares.ai/app/apps/integrations/slack/complete`,
  FF_ACCOUNT_PREFILLING: true
};

const test: DtektConfig = {
  NAME: "development",
  API_ENDPOINT_URL: "https://localhost:8443",
  FRONTEND_URL: "https://localhost:3000",
  RUM_API_ADDRESS: "",
  RUM_WS_API_ADDRESS: "",
  TSDB_ADDRESS: "localhost:7002",
  GOOGLE_CLIENT_ID: "",
  GOOGLE_REDIRECT_URI: "",
  STRIPE_STARTER_PRICE_ID: "",
  STRIPE_STARTER_PROD_ID: "",
  STRIPE_BASIC_PRICE_ID: "",
  STRIPE_BASIC_PROD_ID: "",
  STRIPE_PREMIUM_PRICE_ID: "",
  STRIPE_PREMIUM_PROD_ID: "",
  UPTIME_MONITOR_ENABLE_TCP_CHECK: true,
  APP_STAGING: false,
  PAGERDUTY_REDIRECT_URI: "",
  PAGERDUTY_APP_ID: "",
  SLACK_INTEGRATION_INSTALLATION_URL: "",
  FF_ACCOUNT_PREFILLING: false
};

const getEnv = (): DtektConfig => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":

      if (window.location.hostname == "app-staging.myshares.ai") {
        prod.APP_STAGING = true
      }

      return prod;
    default:
      return test;
  }
};

export const env = getEnv();
