import React from "react";
import { Dropdown, Button } from "antd";
import { connect } from "react-redux";
import {
  MenuOutlined,
  LogoutOutlined,
  SwitcherOutlined
} from "@ant-design/icons";
import AccountService from "services/AccountService";
import { useHistory } from "react-router-dom";
import { accountSwitched } from "redux/actions/Account";
import TrimmedText from "components/shared-components/TrimmedText";

const ACTION_ACCOUNT_SWITCH = "DropMenuswitchAccount"
const ACTION_LOGOUT = "DropMenuLogout"
const ACTION_SETTINGS = "DropMenuSettings"

function getItem(label, key, icon, children, danger, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    danger
  };
}

export const NavProfile = (props) => {
  let history = useHistory();

  if (props.userInfo === null) {
    return <></>;
  }

  const handleMenuClick = e => {
    if (e.key === ACTION_SETTINGS) {
      history.push("/app/pages/settings");
    }

    if (e.key === ACTION_LOGOUT) {
      history.push("/auth/logout");
    }

    if (e.keyPath.length == 2) {
      if (e.keyPath[1] === ACTION_ACCOUNT_SWITCH) {
        switchAccount(e.keyPath[0])
      }
    }

    return null
  };


  const switchAccount = (accountId) => {
    if (props.account.id === accountId) {
      return true;
    } else {
      AccountService.get(accountId).then((account) => {
        props.accountSwitched(account);
        history.push("/");
      });
    }
  };

  const getAccounts = () => {
    let accounts = [];
    if (props.userInfo == null) {
      return accounts
    }

    props.userInfo.accounts.forEach((acc) => {
      accounts.push(
        getItem(<TrimmedText text={acc.name} maxLength={20} />, acc.id)
      );
    });

    return accounts;
  };

  let menuProps = {
    items: [
      // getItem('Help', ACTION_HELP, <QuestionCircleOutlined />),
      // getItem('Switch account', ACTION_ACCOUNT_SWITCH, <SwitcherOutlined />, getAccounts()),
      getItem('Logout', ACTION_LOGOUT, <LogoutOutlined />, null, true),
    ],
    mode: "vertical",
    onClick: handleMenuClick
  }

  return (
    <div>
      <Dropdown trigger={['click']} menu={menuProps}>
        <Button type="secondary">
          <MenuOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
    userInfo: state.user.userInfo,
  };
};

const mapDispatchToProps = {
  accountSwitched,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
