const initState = {
    stocksAvailable: null,
    stocksSubscribed: null,
    stockUpdates: null
};

const account = (state = initState, action) => {
    switch (action.type) {
        case "SET_STOCKS_AVAILABLE":
            return {
                ...state,
                stocksAvailable: action.data,
            };
        case "SET_STOCKS_SUBSCRIBED":
            return {
                ...state,
                stocksSubscribed: action.data,
            };
        case "SET_STOCK_UPDATES":
            return {
                ...state,
                stockUpdates: action.data,
            };
        default:
            return state;
    }
};

export default account;
