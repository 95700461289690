import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import withMinimumOneAccount from "hoc/withMinimumOneAccount";
import withAccountChosen from "hoc/withAccountChosen";
import { INITIAL_SCREEN } from "configs/AppConfig";
import AuthController from "controllers/AuthController";

export const AppViews = (props) => {
  let history = useHistory();

  // If the logged off requested a protected area
  // of the application let's redirect him to it
  try {
    const location = AuthController.getAuthRedirect()

    if (location) {
      if (![INITIAL_SCREEN, "/", "/app", "/app/apps"].includes(location.pathname)) {
        history.push(location.pathname)
      }
      AuthController.resetAuthRedirect()
    }
  } catch {
    console.error("Auth redirect has failed")
    AuthController.resetAuthRedirect()  
    history.push(INITIAL_SCREEN);
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${props.match.url}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          component={lazy(() => import(`./apps`))}
          path={`${props.match.url}/apps`}
        />
        <Route
          component={lazy(() => import(`./editorial-office`))}
          path={`${props.match.url}/editorial-office`}
        />
        <Redirect from={`${props.match.url}`} to={`${INITIAL_SCREEN}`} />
      </Switch>
    </Suspense>
  );
};

export default withMinimumOneAccount(withAccountChosen(AppViews));
