import {
  UserOutlined,
  MoneyCollectOutlined,
  BugOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { ROLE_ADMIN, ROLE_OWNER } from "./Roles";


const feedTree = [
  {
    key: "menu",
    path: "",
    title: "Menu",
    breadcrumb: false,
    submenu: [
      {
        key: "feed",
        path: "/app/apps/dashboard",
        title: "Feed",
        icon: OrderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "management-subscriptions",
        path: "/app/admin/subscriptions",
        title: "sidenav.management.subscriptions",
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        submenu: [],
        roleAccess: [ROLE_ADMIN, ROLE_OWNER]
      },
      {
        key: "feedback-bug-report",
        path: "/app/pages/feedback",
        title: "sidenav.management.feedbackbugreport",
        icon: BugOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];


const navigationConfig = [
  ...feedTree
];

export default navigationConfig;
