// Used when user didn't configure subscription yet
export const ACTION_CHECKOUT = "perform_checkout_session"

// Used when user has subscription and we just need to add additional product
// to his subscriptin.
export const ACTION_ITEM_ADD = "add_item_to_existing_subscription"

// Used when user removes a stock feed
export const ACTION_ITEM_REMOVE = "remove_item_from_existing_subscription"

const emptyTour = {
    ticker: null,
    open: false,
    title: "Choose the stock feed you want to subscribe",
    buttonText: "Next",
    buttonClass: "displaynone",
    subscriptionInfo: undefined,
    action: ACTION_CHECKOUT,
    isLastItem: false
}

const initState = {
    subscriptionInfo: undefined,
    // Tour is the flow user follows to create new subscriptions
    tour: emptyTour,
    billingDetailsConfigured: null
};

const subscriptions = (state = initState, action) => {
    switch (action.type) {
        case "SUBTOUR_OPEN":
            return {
                ...state,
                tour: {
                    ...state.tour,
                    open: true
                }
            }
        case "SUBTOUR_BILLING_DETAILS_CONFIGURED":
            return {
                ...state,
                billingDetailsConfigured: action.data,
            };
        case "SUBTOUR_TICKER_CHOSEN":
            let typeOfFlow = !state.subscriptionInfo ? ACTION_CHECKOUT : ACTION_ITEM_ADD

            if (typeOfFlow === ACTION_CHECKOUT) {
                return {
                    ...state,
                    tour: {
                        ...state.tour,
                        ticker: action.data.ticker,
                        buttonText: "Proceed to checkout",
                        buttonClass: undefined,
                        title: "Billing details",
                        action: ACTION_CHECKOUT
                    }
                };
            }

            if (typeOfFlow === ACTION_ITEM_ADD) {
                return {
                    ...state,
                    tour: {
                        ...state.tour,
                        ticker: action.data.ticker,
                        buttonText: "Pay & Subscribe",
                        buttonClass: undefined,
                        title: "Confirmation",
                        action: ACTION_ITEM_ADD
                    }
                };
            }
        case "SUBTOUR_GO_BACK_TO_TICKER_CHOOSING":
            return {
                ...state,
                tour: {
                    ...state.tour,
                    ticker: null,
                    open: true
                }
            }
        case "SUBTOUR_REMOVE_STOCK_FROM_SUBSCRIPTION":
            return {
                ...state,
                tour: {
                    ...state.tour,
                    ticker: action.data.ticker,
                    action: ACTION_ITEM_REMOVE,
                    open: true,
                    title: "Confirmation",
                    buttonClass: undefined,
                    buttonText: state.subscriptionInfo.subscribed_to.length === 1 ? `Downgrade to free account` : `Remove ${action.data.ticker} from subscription`,
                    isLastItem: state.subscriptionInfo.subscribed_to.length === 1 ? true : false
                }
            }
        case "SUBTOUR_RESET":
            return {
                ...state,
                tour: {...emptyTour}
            }
        case "SET_SUBSCRIPTION_INFO":
            return {
                ...state,
                subscriptionInfo: action.data,
            }
        default:
            return state;
    }
};

export default subscriptions;